.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row;
}

.lms_overview {
    margin-top: -5em;
}

.add_icon {
    transform: scale(2);
}

// datagrid
.button {
    border: none;
    margin-top: 0.4em;
    background: none;
}

.button_left {
    @extend .button;
    margin-left: 0.6em;
}