.overlay {
    display: block;
    position: fixed;
    z-index: 1;
    left: 260px;
    top: 0;
    width: calc(100% - 260px);
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal {
    background-color: #fefefe;
    margin: 30% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    border-radius: 10px;

    text-align: center;
}