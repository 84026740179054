.input {
    border: 0;
    background-image: linear-gradient(#1a1c1f, #1a1c1f), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    line-height: 1.428571429;
    display: block;
    width: 100%;
    color: #555;
}

.button_div {
    margin-top: 2em;
}

.modal_button_primary {
    background-color: #333333;
    border: none;
    border-radius: 5%;
    padding: 1em;
    width: 135px;

    font-size: medium;
    font-weight: 600;
    color: white;
}
    
.modal_button_secondary {
    @extend .modal_button_primary;
    background-color: #d13838;
    margin-right: 3em;
}