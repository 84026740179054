.slider-primary {
 &.blue {
    .noUi-connect {
      background-color: #00acc1;
    }

    .noUi-handle {
      border-color: #00acc1;
    }
   
 } 
  &.green {
    .noUi-connect {
      background-color: #43a047;
    }

    .noUi-handle {
      border-color: #43a047;
    }
  }

  &.red {
    .noUi-connect {
      background-color: #364150;
    }

    .noUi-handle {
      border-color: #364150;
    }
  } 
}
