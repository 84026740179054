.course_groups_overview {
    margin-top: -5em;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row;
}

.upload_status {
    padding-top: 10px;
}

.upload_container {
    display: flex;
    align-items: center;
}

.upload_button {
    margin-right: 25px;
}

.file_input {
    display: none;
}

.actions {
    display: flex;
    flex-direction: row;
}

.edit_icon {
    transform: scale(2);
    margin-right: 2em;
}

.delete_icon {
    transform: scale(2);
}

.data {
    font-size: 16px;
}

.zip_success {
    margin-top: .5em;
    color: green;
}

.datagrid {
    margin-top: 5em;
}

.datagrid_header {
    margin-bottom: 0em;
}

// datagrid
.button {
    border: none;
    margin-top: 0.4em;
    background: none;
}

.button_left {
    @extend .button;
    margin-left: 0.6em;
}

.lds_spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds_spinner div {
    transform-origin: 40px 40px;
    animation: lds_spinner 1.2s linear infinite;
  }
  .lds_spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds_spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds_spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds_spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds_spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds_spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds_spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds_spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds_spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds_spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds_spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds_spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds_spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds_spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  